import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MenuModal({ open, onClose, children }) {
  const [openDialog, setOpenDialog] = React.useState(open);

  const handleClose = () => {
    setOpenDialog(false);
    onClose();
  };

  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  return (
    <Dialog
      className="menu-modal"
      fullScreen
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <IconButton
        edge="start"
        color="primary"
        onClick={handleClose}
        aria-label="close"
        className="modal-close"
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Dialog>
  );
}
