import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
//components
import Banner from "components/Banner/Banner";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShopSelectShipping({ open, onClose, children }) {
  const [openDialog, setOpenDialog] = React.useState(open);

  const handleClose = () => {
    setOpenDialog(false);
    onClose();
  };

  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  return (
    <Dialog
      className="order-shipping-modal"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
        <Banner
          title="Tipo de entrega"
          cart={false}
        />
      {children}
    </Dialog>
  );
}
