import React, { Component } from "react";
import api from "api";
import { currencyFormat } from "utils/helpers";
import { withRouter } from "react-router-dom";
import headerImage from "assets/img/header.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { Button } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LocationOnIcon from "@material-ui/icons/LocationOn";
//components
import Banner from "components/Banner/Banner";
import ShopSelectShipping from "components/Shop/ShopSelectShipping";
//redux
import { connect } from "react-redux";
import {
  updateCart,
  removeToCart,
  emptyCart,
} from "redux/actions/cart.actions";
//icons
import shippingIcon from "assets/img/icons/shipping.png";
//images

const Zones = [
  { name: "Zona 1", value: 1, cost: 60, color: "#79bd01" },
  { name: "Zona 2", value: 2, cost: 80, color: "#fe7f18" },
  { name: "Zona 3", value: 3, cost: 90, color: "#fa2340" },
  { name: "Zona 4", value: 4, cost: 100, color: "#02c7d6" },
];

class ConfirmPage extends Component {
  constructor({ items, total, emptyCart, history, whatsappNumber }) {
    super();
    this.state = {
      showSelectShipping: false,
      shipping: "",
      clientName: "",
      shippingCost: 0,
      addressStreet: "",
      addressNumber: "",
      addressBetween: "",
      addressOffice: "",
      zone: null,
    };
  }

  componentDidMount() {
    this.setState({
      orderNumber: Math.random().toString(36).substr(2, 4).toUpperCase(),
    });
  }

  handleChangeShipping = (event) => {
    this.setState({
      shipping: event.target.value,
      shippingCost: null,
      clientName: "",
      shippingCost: null,
      addressStreet: "",
      addressNumber: "",
      addressBetween: "",
      addressOffice: "",
      zone: null,
    });
  };

  handleChangeField = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeZone = (event) => {
    this.setState({
      zone: event.target.value,
      shippingCost: Zones.filter((z) => z.value === event.target.value)[0].cost,
    });
  };

  handleSendWhatsApp = () => {
    const { items, total, emptyCart, whatsappNumber } = this.props;
    const {
      orderNumber,
      shipping,
      clientName,
      shippingCost,
      addressStreet,
      addressNumber,
      addressBetween,
      addressOffice,
      zone,
    } = this.state;
    let phoneNumberVendor = whatsappNumber;
    let itemsText = "";
    items.forEach((item) => {
      itemsText +=
        encodeURI(
          `- ${item.cant}x ${item.title} ${item.variant && item.variant.name ? `(${item.variant.name})` : ""} ${currencyFormat(
            item.cant * item.price
          )}`
        ) + "%0D%0A";
    });
    if (shipping !== "takeaway" && shippingCost > 0) {
      itemsText +=
        encodeURI(`- Costo de envío ${currencyFormat(shippingCost)}`) +
        "%0D%0A";
    }
    let totalText = "%0D%0A" + encodeURI(`*TOTAL $${total + shippingCost}*`);
    let numberText =
      `%0D%0A%0D%0A` +
      encodeURI(`Código de pedido: ${orderNumber.toString().toUpperCase()}`);
    let clientNameText =
      `%0D%0A` +
      encodeURI(
        `${
          clientName
            ? `Nombre: ${clientName}`
            : ""
        }`
      );
    let address = `${addressStreet} N° ${addressNumber} entre ${addressBetween} ${addressOffice}`;
    let shippingText =
      `%0D%0A` +
      encodeURI(
        `${
          shipping === "takeaway"
            ? "Retiro en el local."
            : `Delivery a: ${address} `
          /*   (${
                Zones.filter((z) => z.value === zone)[0].name
              }).` */
        }`
      );
    let orderText =
      encodeURI(`¡Hola!, quiero hacer el siguiente pedido:`) +
      "%0D%0A" +
      itemsText +
      totalText +
      numberText +
      clientNameText +
      shippingText;
    this.postOrder();
    emptyCart();
    this.goToHomePage();
    window.open(`https://wa.me/${phoneNumberVendor}/?text=${orderText}`);
  };

  async postOrder() {
    const { items, total } = this.props;
    const {
      orderNumber,
      shipping,
      addressStreet,
      addressNumber,
      addressBetween,
      addressOffice,
      zone,
    } = this.state;
    let dataOrder = {
      items: JSON.stringify(items),
      total: total,
      orderCode: orderNumber,
      shipping: shipping,
      address: `${addressStreet} N° ${addressNumber} entre ${addressBetween} ${addressOffice}`,
      //zone: zone ? Zones.filter((z) => z.value === zone)[0].name : null,
      zone: "La Plata"
    };
    await api
      .post(`/vendor/${process.env.REACT_APP_VENDOR_ID}/order`, dataOrder)
      .then((result) => {
        console.log(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  goToHomePage() {
    const { history } = this.props;
    history.push("/");
    localStorage.removeItem("wegant_menu_cart");
  }

  renderShippingCost = (zoneValue) => {
    const { shippingCost } = this.state;
    return (
      <React.Fragment>
        Costo de envío
        <div className="float-right">{currencyFormat(shippingCost)}</div>
      </React.Fragment>
    );
  };

  render() {
    const {
      shipping,
      shippingCost,
      showSelectShipping,
      clientName,
      addressStreet,
      addressNumber,
      addressBetween,
      addressOffice,
      zone,
      orderNumber,
    } = this.state;
    const { items, total } = this.props;
    let address = addressStreet
      ? `${addressStreet} N° ${addressNumber} entre ${addressBetween} ${addressOffice}`
      : "";
    return (
      <div className="confirm">
        <header>
          <Banner
            //title="Confirmar pedido"
            back="/cart/"
            bg={headerImage}
            cart={true}
            colorIconsInvert
          />
        </header>
        <main>
          <a
            className="order-address"
            href="javascript:void(0)"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ showSelectShipping: true });
            }}
          >
            <div className="d-flex">
              <div className="left-col">
                <img src={shippingIcon} alt="entrega" />
              </div>
              <div className="middle-col w-100">
                <span
                  className="color-primary"
                  style={{ lineHeight: !address ? "45px" : null }}
                >
                  {shipping
                    ? shipping === "takeaway"
                      ? "Retirar en el local"
                      : "Envío a domicilio"
                    : "Seleccioná el tipo de entrega"}
                </span>
                {address ? (
                  <small>
                    {address} 
               {/*      (
                    {zone
                      ? Zones.filter((z) => z.value === zone)[0].name
                      : null}
                    ) */}
                  </small>
                ) : null}
              </div>
              <div className="right-col">
                <span>Editar</span>
              </div>
            </div>
          </a>

          <ShopSelectShipping
            open={showSelectShipping}
            onClose={() => this.setState({ showSelectShipping: false })}
          >
            <div className="p-3" style={{ overflow: "auto" }}>
              <div className="row">
                <div className="col-12">
                  <FormControl component="div" className="order-shipping">
                    <RadioGroup
                      aria-label="shipping"
                      name="shipping"
                      value={shipping}
                      onChange={this.handleChangeShipping}
                    >
                      <FormControlLabel
                        value="takeaway"
                        control={<Radio />}
                        label="Retirar en el local"
                      />
                      <FormControlLabel
                        value="delivery"
                        control={<Radio />}
                        label="Envío a domicilio"
                      />
                    </RadioGroup>
                  </FormControl>
                  {shipping && shipping !== "takeaway" ? (
                    <React.Fragment>
                      <TextField
                        className="mb-3 w-100"
                        label="Nombre"
                        variant="outlined"
                        value={clientName}
                        name={"clientName"}
                        onChange={this.handleChangeField}
                        required
                      />
                      <div className="d-flex w-100 mb-3">
                        <TextField
                          className="mr-3"
                          label="Calle"
                          variant="outlined"
                          value={addressStreet}
                          name={"addressStreet"}
                          onChange={this.handleChangeField}
                          required
                        />
                        <TextField
                          label="Número"
                          variant="outlined"
                          value={addressNumber}
                          name={"addressNumber"}
                          onChange={this.handleChangeField}
                          required
                        />
                      </div>
                      <TextField
                        className="mb-3 w-100"
                        label="Entre"
                        variant="outlined"
                        value={addressBetween}
                        name={"addressBetween"}
                        onChange={this.handleChangeField}
                        required
                      />
                      <TextField
                        className="mb-3 w-100"
                        label="Dpto/Oficina/Interno"
                        variant="outlined"
                        value={addressOffice}
                        name={"addressOffice"}
                        onChange={this.handleChangeField}
                      />
                      <small className="text-muted d-block mb-3">* El costo de envío será informado por el negocio al momento de confirmar el pedido.</small>
                      {/* <FormControl variant="outlined" className="mb-3 w-100">
                        <InputLabel id="zone-label">
                          Seleccioná la zona de envío
                        </InputLabel>
                        <Select
                          labelId="zone-label"
                          value={zone}
                          onChange={this.handleChangeZone}
                          label="Seleccioná la zona de envío"
                          required
                        >
                          {Zones.map((z) => {
                            return (
                              <MenuItem value={z.value}>
                                <ListItemIcon>
                                  <LocationOnIcon
                                    htmlColor={z.color}
                                    fontSize="small"
                                  />
                                </ListItemIcon>
                                {z.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <img
                          src={rangoZonasImg}
                          style={{
                            width: "100%",
                            margin: "15px auto 0px auto",
                            borderRadius: "5px",
                          }}
                        />
                      </FormControl> */}
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
              <div className="footer-btn">
                <div className="btn">
                  <Button
                    onClick={() => this.setState({ showSelectShipping: false })}
                    disabled={
                      shipping !== "takeaway" &&
                      (
                        //!zone ||
                        !clientName.trim() ||
                        !addressStreet.trim() ||
                        !addressNumber.trim() ||
                        !addressBetween.trim())
                        ? true
                        : false
                    }
                  >
                    Confirmar
                  </Button>
                </div>
              </div>
            </div>
          </ShopSelectShipping>

          {shipping === "takeaway" ? (
            <p>
              Para retirar en el local debe presentar el código de pedido en la
              caja.
            </p>
          ) : null}

          <div className="order-info">
            <label className="order-label">Código de pedido</label>
            <h3 className="order-number">{orderNumber}</h3>
          </div>
          <div className="shop-add-button btn">
            <div className="row box-details">
              {/* Items details */}
              {items.map((item) => {
                return (
                  <div className="col-12 text-left item-details">
                    {`${item.cant}x ${item.title}`} {item.variant && item.variant.name ? `(${item.variant.name})` : ""}
                    <div className="float-right">
                      {currencyFormat(item.cant * item.price)}
                    </div>
                  </div>
                );
              })}
              {/* Shipping cost */}
  {/*             {zone && shipping !== "takeaway" ? (
                <div className="col-12 text-left item-details">
                  {this.renderShippingCost(zone)}
                </div>
              ) : null} */}
            </div>
            <h4 className="mt-1 text-left">
              Total
              <span className="float-right">
                {currencyFormat(total + shippingCost)}
              </span>
            </h4>
            <Button
              onClick={() => this.handleSendWhatsApp()}
              disabled={
                shipping !== "takeaway" &&
                (
                  //!zone ||
                  !clientName ||
                  !addressStreet ||
                  !addressNumber ||
                  !addressBetween)
                  ? true
                  : false
              }
            >
              Enviar pedido por WhatsApp
            </Button>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    items: state.cartReducers.items,
    total: state.cartReducers.total,
    whatsappNumber: state.profileReducers.whatsapp,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    updateCart,
    removeToCart,
    emptyCart,
  })(ConfirmPage)
);
