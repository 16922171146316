import React from "react";
import { currencyFormat } from "utils/helpers";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
//common
import ProgressiveImage from "common/ProgressiveImage/ProgressiveImage";
//components
import MenuModal from "components/MenuModal/MenuModal";
import ShopCountButton from "components/Shop/ShopCountButton";
import ShopAddButton from "components/Shop/ShopAddButton";
import ShopSelectVariant from "components/Shop/ShopSelectVariant";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "95%",
  },
}));

function SuggestionCard({ id, title, description, variants, image }) {
  const classes = useStyles();

  let defaultVariant = null;
  if (variants && variants.length > 1) {
    defaultVariant = {
      id: variants[0].option ? variants[0].option[0].id : null,
      code: variants[0].option ? variants[0].option[0].code : null,
      name: variants[0].option ? variants[0].option[0].value : null,
      price: variants[0].price,
    };
  }

  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(1);
  const [price, setPrice] = React.useState(variants ? variants[0].price : null);
  const [variant, setVariant] = React.useState(defaultVariant);

  let descriptionArray = description ? description.split("/") : null;

  return (
    <React.Fragment>
      <Card className={classes.root} onClick={() => setOpen(true)}>
        <CardMedia>
          <ProgressiveImage image={image} />
        </CardMedia>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <span className="title">{title}</span>
            <span className="description text-truncate w-100 d-inline-block">
              {description}
            </span>
            <span className="price">{currencyFormat(price)}</span>
          </Typography>
        </CardContent>
      </Card>

      <MenuModal open={open} onClose={() => setOpen(false)}>
        <CardMedia>
          <ProgressiveImage image={image} />
        </CardMedia>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <div className="d-flex">
              <span className="title w-100 mr-3 mt-1 mb-3">{title}</span>
              <span className="price">{currencyFormat(price)}</span>
            </div>
            {process.env.REACT_APP_ENABLE_CART ? (
              <div className="d-flex mb-3">
                <div className="cant">
                  <ShopCountButton
                    label="Cantidad"
                    onChange={(cant) => setCount(cant)}
                  />
                </div>
                <div className="variant">
                  <ShopSelectVariant
                    variants={variants}
                    onChange={(v) => {
                      setPrice(v.price);
                      setVariant(v);
                    }}
                  />
                </div>
              </div>
            ) : null}
            <span className="description">
              {descriptionArray
                ? descriptionArray.map((descriptionPart, i) => (
                    <p key={i}>{descriptionPart}</p>
                  ))
                : null}
            </span>
            {process.env.REACT_APP_ENABLE_CART ? (
              <ShopAddButton
                item={{
                  id: id,
                  title: title,
                  description: description,
                  variant: variant,
                  price: price,
                  image: image,
                }}
                cant={count}
                afterAdd={() => {
                  setOpen(false);
                }}
              />
            ) : null}
          </Typography>
        </CardContent>
      </MenuModal>
    </React.Fragment>
  );
}

export default SuggestionCard;
