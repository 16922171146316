import React, { Component } from "react";
import { Button } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
//redux
import { connect } from "react-redux";
import { addToCart } from "redux/actions/cart.actions";
//icons
import CartIcon from "assets/img/icons/cart.png";

class ShopAddButton extends Component {
  constructor({ item, cant, afterAdd, addToCart, items, total }) {
    super();
  }

  handleClick() {
    const { item, cant, afterAdd, addToCart } = this.props;
    addToCart(item, cant);
    afterAdd();
  }

  getTotalItems() {
    const { items } = this.props;
    let total = 0;
    items.forEach((element) => {
      total = total + element.cant;
    });
    return total;
  }

  render() {
    const { items, total } = this.props;
    return process.env.REACT_APP_ENABLE_CART ? (
      <div className="shop-add-button btn">
        <Button onClick={() => this.handleClick()}>
          <span className="shop-cant">
            <Badge badgeContent={this.getTotalItems()} color="secondary">
              <img src={CartIcon} alt="carrito" />
            </Badge>
          </span>{" "}
          Agregar al pedido <span className="shop-total">${total}</span>
        </Button>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state, props) => {
  return {
    items: state.cartReducers.items,
    total: state.cartReducers.total,
  };
};

export default connect(mapStateToProps, { addToCart })(ShopAddButton);
