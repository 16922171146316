export const setCart = (cart) => {
    return {
        type: 'SET_CART',
        payload: cart
    }
};

export const emptyCart = () => {
    return {
        type: 'EMPTY_CART'
    }
};

export const addToCart = (item, cant) => {
    return {
        type: 'ADD_TO_CART',
        payload: {item: item, cant: cant}
    }
};

export const removeToCart = (item, cant) => {
    return {
        type: 'REMOVE_TO_CART',
        payload: {item: item}
    }
};

export const updateCart = (item, cant) => {
    return {
        type: 'UPDATE_CART',
        payload: {item: item, cant: cant}
    }
};