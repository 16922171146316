import React from "react";
import SuggestionCard from "components/SuggestionCard/SuggestionCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 20,
  },
};

class Suggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: null,
    };
    this.idIntervalStorage = null;
  }

  componentDidMount() {
    this.idIntervalStorage = setInterval(() => this.getProducts(), 500);
  }

  componentWillUnmount() {
    clearInterval(this.idIntervalStorage);
  }

  getProducts() {
    let data = JSON.parse(localStorage.getItem("wegant_menu_products"));
    if (data) {
      clearInterval(this.idIntervalStorage);
      let dataFeatured = data.items.filter(item => item.featured);
      this.setState({
        products: {
          items: dataFeatured,
          total: data.total,
        },
      });
    }
  }

  render() {
    const { products } = this.state;
    return products ? (
      <div className="suggestions">
        <div className="suggestions-title">
          <span>Sugerencias</span>
          {/*          <a href="">Ver todos ({products.total})</a> */}
        </div>
        <div className="suggestions-body">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            draggable
            focusOnSelect={false}
            infinite={false}
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            showDots={false}
            slidesToSlide={1}
            swipeable
          >
            {products.items
              ? products.items.map((item, index) => {
                  return (
                    <div key={`i${index}`}>
                      <SuggestionCard
                        id={item.id}
                        title={item.name}
                        variants={item.variants}
                        description={item.description}
                        image={
                          item.image
                            ? process.env.REACT_APP_API_URL +
                              "/media/image/" +
                              item.image
                            : null
                        }
                      />
                    </div>
                  );
                })
              : null}
          </Carousel>
        </div>
      </div>
    ) : (
      <div className="spinner">
        <i />
        <p>Cargando...</p>
      </div>
    );
  }
}

export default Suggestions;
