const initialState = {
  items: [],
  total: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CART": {
      const { items, total } = action.payload;
      return {
        items: items,
        total: total,
      };
    }
    case "EMPTY_CART": {
      return initialState;
    }
    case "ADD_TO_CART": {
      const { item, cant } = action.payload;
      let newTotal = state.total + item.price * cant;
      let existed_item = state.items.find((i) => (i.id === item.id && !i.variant) || (i.id === item.id && i.variant && i.variant.id === item.variant.id));
      if (existed_item) {
        existed_item.cant = existed_item.cant + cant;
        let newState = {
          ...state,
          total: newTotal,
        };
        localStorage.setItem("wegant_menu_cart", JSON.stringify(newState));
        return newState;
      } else {
        item.cant = cant;
        let newState = {
          ...state,
          items: [...state.items, item],
          total: newTotal,
        };
        localStorage.setItem("wegant_menu_cart", JSON.stringify(newState));
        return newState;
      }
    }
    case "UPDATE_CART": {
      const { item, cant } = action.payload;
      let existed_item = state.items.find((i) => (i.id === item.id && !i.variant) || (i.id === item.id && i.variant && i.variant.id === item.variant.id));
      if (existed_item) {
        existed_item.cant = cant;
        let newTotal = 0;
        state.items.forEach((i) => {
          newTotal += i.price * i.cant;
        });
        let newState = {
          ...state,
          total: newTotal,
        };
        localStorage.setItem("wegant_menu_cart", JSON.stringify(newState));
        return newState;
      }
    }
    case "REMOVE_TO_CART": {
      const { item } = action.payload;
      let newTotal = 0;
      let filtered_items = [];
      if (item.variant) {
        filtered_items = state.items.filter((i) => (!i.variant) || (i.variant && i.variant.id !== item.variant.id));
      } else {
        filtered_items = state.items.filter((i) => (i.id !== item.id));
      }
      filtered_items.forEach((i) => {
        newTotal = newTotal + i.price * i.cant;
      });
      let newState = {
        items: filtered_items,
        total: newTotal,
      };
      localStorage.setItem("wegant_menu_cart", JSON.stringify(newState));
      return newState;
    }
    default:
      return state;
  }
};
