import React from "react";
import headerImage from "assets/img/header.png";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { currencyFormat } from "utils/helpers";
//redux
import { connect } from "react-redux";
import { updateCart, removeToCart } from "redux/actions/cart.actions";
//common
import ProgressiveImage from "common/ProgressiveImage/ProgressiveImage";
//components
import Banner from "components/Banner/Banner";
import ShopCountButton from "components/Shop/ShopCountButton";
//icons
import RemoveIcon from "assets/img/icons/remove.png";

function CartPage({ items, total, updateCart, removeToCart }) {
  const handleUpdateCart = (item, cant) => {
    updateCart(item, cant);
  };

  const handleDeleteItemCart = (item) => {
    removeToCart(item);
  };

  const history = useHistory();

  return (
    <div className="cart">
      <header>
        <Banner
          title="Detalle de pedido"
          back="/"
          bg={headerImage}
          cart={false}
        />
      </header>
      <main>
        {items.length > 0 ? (
          items.map((item, index) => {
            let mt = index === 0 ? { marginTop: "15px" } : null;
            return (
              <React.Fragment>
                <div className="cart-item" style={mt} key={index}>
                  <div className="cart-image">
                    <ProgressiveImage image={item.image} />
                  </div>
                  <span className="cart-title">{item.title} {`${item.variant && item.variant.name ? `(${item.variant.name})` : ""}`}</span>
                  <div className="cart-delete-item">
                    <Button onClick={() => handleDeleteItemCart(item)}>
                      <img src={RemoveIcon} alt="eliminar" />
                    </Button>
                  </div>
                  <span className="cart-price">
                    {currencyFormat(item.cant * item.price)}
                  </span>
                  <div className="cart-cant-item">
                    <ShopCountButton
                      cant={item.cant}
                      onChange={(cant) => handleUpdateCart(item, cant)}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <p className="empty-cart">No hay productos agregados en el carrito</p>
        )}
        <div className="shop-add-button btn">
          <h5 className="text-left">
            Total <span className="float-right">{currencyFormat(total)}</span>
          </h5>
          <Button
            onClick={() => history.push("/confirm/")}
            disabled={items.length > 0 ? false : true}
          >
            Confirmar pedido
          </Button>
        </div>
      </main>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    items: state.cartReducers.items,
    total: state.cartReducers.total,
  };
};

export default connect(mapStateToProps, { updateCart, removeToCart })(CartPage);
