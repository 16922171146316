import React, { Component } from "react";
import { Button } from "@material-ui/core";
//icons
import SubtractIcon from "assets/img/icons/subtract.png";
import AddIcon from "assets/img/icons/add.png";

export default class ShopCountButton extends Component {
  constructor({ cant = 1, label = null, onChange }) {
    super();
    this.state = {
      count: cant,
    };
  }

  componentDidUpdate() {
    if (this.props.cant && this.props.cant !== this.state.count) {
      this.setState({
        count: this.props.cant,
      });
    }
  }

  render() {
    const { onChange, label } = this.props;
    const { count } = this.state;
    return process.env.REACT_APP_ENABLE_CART ? (
      <React.Fragment>
        {label ? <label>{label}</label> : null}
        <div className="shop-count-button">
          <Button
            onClick={() => {
              let newCount = count > 1 ? count - 1 : 1;
              this.setState(({ count }) => ({
                count: newCount,
              }));
              onChange(newCount);
            }}
          >
            <img src={SubtractIcon} alt="menos" />
          </Button>
          <span>{count}</span>
          <Button
            onClick={() => {
              let newCount = count + 1;
              this.setState(({ count }) => ({
                count: count + 1,
              }));
              onChange(newCount);
            }}
          >
            <img src={AddIcon} alt="más" />
          </Button>
        </div>
      </React.Fragment>
    ) : null;
  }
}
