const initialState = {
  whatsapp: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROFILE": {
      const { whatsapp } = action.payload;
      console.log(whatsapp);
      return {
        whatsapp: whatsapp,
      };
    }
    default:
      return state;
  }
};
