import React from "react";
import Routes from "Routes";
import axios from "axios";
import api from "api";
import "theme/base.scss";
//redux
import { connect } from "react-redux";
import { setCart } from "redux/actions/cart.actions";
import { setProfile } from "redux/actions/profile.actions";

let token = localStorage.getItem("wegant_token")
  ? JSON.parse(localStorage.getItem("wegant_token"))
  : null;

class App extends React.Component {
  constructor({ setCart, setProfile }) {
    super();
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    if (!token) {
      this.getToken();
    } else {
      this.refreshToken(token);
    }
    //if cart feature is enabled
    if (process.env.REACT_APP_ENABLE_CART) {
      this.loadCart();
    }
  }

  async getToken(token) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}api/oauth/v2/token`, {
        params: {
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          grant_type: process.env.REACT_APP_GRANT_TYPE,
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD,
        },
      })
      .then((response) => {
        token = response.data;
        localStorage.setItem("wegant_token", JSON.stringify(token));
        this.fetchTaxons();
        this.fetchProducts();
        this.fetchUserProfile();
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  }

  async refreshToken(token) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}api/oauth/v2/token`, {
        params: {
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          grant_type: "refresh_token",
          refresh_token: token.refresh_token,
        },
      })
      .then((response) => {
        token = response.data;
        localStorage.setItem("wegant_token", JSON.stringify(token));
        this.fetchTaxons();
        this.fetchProducts();
        this.fetchUserProfile();
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        localStorage.setItem("wegant_token", null);
        this.getToken(token);
      });
  }

  async fetchTaxons() {
    await api
      .get(`/vendor/${process.env.REACT_APP_VENDOR_ID}/taxons`)
      .then((result) => {
        let data = {
          total: result.data.length,
          items: result.data,
        };
        localStorage.setItem("wegant_menu_taxons", JSON.stringify(data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async fetchProducts() {
    await api
      .get(`/vendor/${process.env.REACT_APP_VENDOR_ID}/products`)
      .then((result) => {
        let data = {
          total: result.data.length,
          items: result.data,
        };
        localStorage.setItem("wegant_menu_products", JSON.stringify(data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async fetchUserProfile() {
    const { setProfile } = this.props;
    await api
      .get(`/vendor/${process.env.REACT_APP_VENDOR_ID}/profile`)
      .then((result) => {
        let data = {
          whatsapp: result.data.whatsapp,
        };
        setProfile(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  loadCart() {
    const { setCart } = this.props;
    let cartState = JSON.parse(localStorage.getItem("wegant_menu_cart"));
    if (cartState) {
      setCart(cartState);
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="App">
        {loading ? (
          <div className="spinner">
            <i />
            <p>Cargando...</p>
          </div>
        ) : (
          <Routes />
        )}
      </div>
    );
  }
}

export default connect(null, { setCart, setProfile })(App);
