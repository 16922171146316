import React from "react";
import { Switch, Route } from "react-router-dom";
//views
import HomePage from "views/HomePage";
import MenuPage from "views/MenuPage";
import ShowPage from "views/ShowPage";
import CartPage from "views/CartPage";
import ConfirmPage from "views/ConfirmPage";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/menu" component={MenuPage} />
      <Route exact path="/menu/:item" component={ShowPage} />
      <Route exact path="/cart/" component={CartPage} />
      <Route exact path="/confirm/" component={ConfirmPage} />
    </Switch>
  );
};

export default Routes;
