import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function ShopSelectVariant({ variants, onChange }) {
  let variantsOptions = [];
  variants.forEach((v) =>
    variantsOptions.push({
      id: v.option ? v.option[0].id : null,
      code:  v.option ? v.option[0].code : null,
      name: v.option ? v.option[0].value : null,
      value: v.price,
    })
  );

  function handleChangeVariant(e) {
    let variantSelected = variants.find(v => v.option[0].id == e.target.value);
    return onChange({
      id: variantSelected.option[0].id,
      code: variantSelected.option[0].code,
      name: variantSelected.option[0].value,
      price: variantSelected.price
    });
  }

  return variantsOptions.length > 1 ? (
    <div className="shop-select-variant">
      <FormControl variant="outlined" className="w-100">
        <label>{variants[0].option[0].name}</label>
        <Select
          defaultValue={variants[0].option[0].id}
          onChange={handleChangeVariant}
          required
        >
          {variantsOptions.map((v) => {
            return <MenuItem value={v.id}>{v.name}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  ) : null;
}
