import React from "react";
import { Link } from "react-router-dom";
//components
import Banner from "components/Banner/Banner";
import MenuCard from "components/MenuCard/MenuCard";
//img
import bgHeader from "assets/img/header.png";

class MenuPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taxons: null,
    };
  }

  componentDidMount() {
    let data = JSON.parse(localStorage.getItem("wegant_menu_taxons"));
    let taxonsParents = data.items.filter((item) => {
      return item.parent === undefined;
    });
    this.setState({
      taxons: taxonsParents,
    });
  }

  render() {
    const { taxons } = this.state;
    return (
      <div className="menu">
        <header>
          <Banner title="Menú" back="/" bg={bgHeader}/>
        </header>
        <main>
          {taxons
            ? taxons.map((item, index) => {
                return (
                  <Link to={`/menu/${item.id}`} key={index}>
                    <MenuCard
                      id={item.id}
                      title={item.name}
                      image={
                        item.image
                          ? process.env.REACT_APP_API_URL +
                            "/media/image/" +
                            item.image
                          : null
                      }
                    />
                  </Link>
                );
              })
            : null}
        </main>
      </div>
    );
  }
}

export default MenuPage;
