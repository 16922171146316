import React from "react";
import headerImage from "assets/img/header.png";
//components
import Banner from "components/Banner/Banner";
import Suggestions from "components/Suggestions/Suggestions";
import MenuFeatured from "components/MenuFeatured/MenuFeatured";
//img
import logoSvg from "assets/img/logo.png";

function HomePage() {
  return (
    <div className="home">
      <header>
        <Banner 
          logo={logoSvg} 
          bg={headerImage} 
        />
      </header>
      <main>
        <Suggestions />
        <MenuFeatured />
      </main>
    </div>
  );
}

export default HomePage;
