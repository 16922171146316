import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import MenuCard from "components/MenuCard/MenuCard";

class MenuFeatured extends React.Component {
  constructor() {
    super();
    this.state = {
      taxons: null,
    };
    this.idIntervalStorage = null;
  }

  componentDidMount() {
    this.idIntervalStorage = setInterval(() => this.getTaxons(), 500);
  }

  componentWillUnmount() {
    clearInterval(this.idIntervalStorage);
  }

  getTaxons() {
    let data = JSON.parse(localStorage.getItem("wegant_menu_taxons"));
    if (data) {
      clearInterval(this.idIntervalStorage);
      this.setState({
        taxons: data,
      });
    }
  }

  render() {
    const { taxons } = this.state;
    let count = 0;
    return taxons ? (
      <div className="featured">
        <div className="featured-title">
          <span>Menú</span>
          <Link to={"/menu"}>Ver todos ({taxons.total})</Link>
        </div>
        <div className="featured-body">
          <div className="row m-0 mb-2">
            {taxons.items.map((item, index) => {
              count = item.featured ? count + 1 : count;
              return item.featured && count <= 6 ? (
                  <React.Fragment key={`i${index}`}>
                    <div className="col-4 px-1 py-0 mb-2">
                      <Link to={`/menu/${item.id}`} key={`j${index}`}>
                        <MenuCard
                          title={item.name}
                          image={
                            item.image
                              ? process.env.REACT_APP_API_URL +
                                "/media/image/" +
                                item.image
                              : null
                          }
                        />
                      </Link>
                    </div>
                  </React.Fragment>
              ) : null;
            })}
          </div>
        </div>
        <div className="featured-body">
          <div className="row m-0 mb-2">
            <Link to="/menu" className="btn">
              <Button>Ver todos ({taxons.total})</Button>
            </Link>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default MenuFeatured;
