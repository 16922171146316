import React from "react";
import { currencyFormat } from "utils/helpers";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
//common
import ProgressiveImage from "common/ProgressiveImage/ProgressiveImage";
//components
import MenuModal from "components/MenuModal/MenuModal";
import ShopCountButton from "components/Shop/ShopCountButton";
import ShopAddButton from "components/Shop/ShopAddButton";
import ShopSelectVariant from "components/Shop/ShopSelectVariant";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
}));

function MenuCard({ id, title, description, variants, image }) {
  const classes = useStyles();

  let defaultVariant = null;
  if (variants && variants.length > 1) {
    defaultVariant = {
      id: variants[0].option ? variants[0].option[0].id : null,
      code: variants[0].option ? variants[0].option[0].code : null,
      name: variants[0].option ? variants[0].option[0].value : null,
      price: variants[0].price,
    };
  }

  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(1);
  const [price, setPrice] = React.useState(variants ? variants[0].price : null);
  const [variant, setVariant] = React.useState(defaultVariant);

  let descriptionArray = description ? description.split("/") : null;

  return (
    <React.Fragment>
      {price ? (
        <Card
          className={`${classes.root} card menu-card`}
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          <div className="row no-gutters">
            {image ? (
              <div className="col-4">
                <CardMedia>
                  <ProgressiveImage image={image} />
                </CardMedia>
              </div>
            ) : null}
            <div className={image ? `col-8` : `col-12`}>
              <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text description">
                  <small className="text-muted text-truncate w-100 d-inline-block">
                    {description}
                  </small>
                </p>
                <p className="card-text price">{currencyFormat(price)}</p>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card className={classes.root}>
          <CardMedia>
            <ProgressiveImage className={`${classes.media}`} image={image} />
          </CardMedia>
          <span className="title">{title}</span>
        </Card>
      )}

      <MenuModal open={open} onClose={() => setOpen(false)}>
        {image ? (
          <CardMedia>
            <ProgressiveImage image={image} />
          </CardMedia>
        ) : null}
        <CardContent className={!image ? "mt-5 pt-4" : null}>
          <Typography variant="body2" color="textSecondary" component="p">
          <div className="d-flex">
              <span className="title w-100 mr-3 mt-1 mb-3">{title}</span>
              <span className="price">{currencyFormat(price)}</span>
            </div>
            {process.env.REACT_APP_ENABLE_CART ? (
              <div className="d-flex mb-3">
                <div className="cant">
                  <ShopCountButton
                    label="Cantidad"
                    onChange={(cant) => setCount(cant)}
                  />
                </div>
                <div className="variant">
                  <ShopSelectVariant
                    variants={variants}
                    onChange={(v) => {
                      setPrice(v.price);
                      setVariant(v);
                    }}
                  />
                </div>
              </div>
            ) : null}
            <span className="description">
              {descriptionArray
                ? descriptionArray.map((descriptionPart, i) => (
                    <p key={i}>{descriptionPart}</p>
                  ))
                : null}
            </span>
            {process.env.REACT_APP_ENABLE_CART ? (
              <ShopAddButton
                item={{
                  id: id,
                  title: title,
                  description: description,
                  variant: variant,
                  price: price,
                  image: image,
                }}
                cant={count}
                afterAdd={() => {
                  setOpen(false);
                }}
              />
            ) : null}
          </Typography>
        </CardContent>
      </MenuModal>
    </React.Fragment>
  );
}

export default MenuCard;
