import React from "react";
import Paper from "@material-ui/core/Paper";
//components
import Banner from "components/Banner/Banner";
import MenuCard from "components/MenuCard/MenuCard";

class ShowPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.item,
      data: null,
    };
  }

  getProductsByTaxon(taxonId) {
    let dataProducts = JSON.parse(localStorage.getItem("wegant_menu_products"));
    let filteredProducts = dataProducts.items.filter((i) => {
      return i.taxon && i.taxon == taxonId;
    });
    return filteredProducts;
  }

  componentDidMount() {
    const { id } = this.state;
    let data = {};
    let dataTaxons = JSON.parse(localStorage.getItem("wegant_menu_taxons"));
    let currentTaxon = dataTaxons.items.filter((i) => {
      return i.id == id;
    });

    let products = this.getProductsByTaxon(id);
    data = {
      taxon: currentTaxon ? currentTaxon[0] : null,
      items: products,
      childrens: [],
    };

    dataTaxons.items.forEach((taxon) => {
      if (taxon.parent && taxon.parent.id === id) {
        let products = this.getProductsByTaxon(taxon.id);
        data.childrens.push({
          taxon: taxon,
          items: products,
        });
      }
    });

    this.setState({
      data: data,
    });
  }

  render() {
    const { data } = this.state;
    return data ? (
      <div className="show-page">
        <header>
          <Banner
            title={data.taxon.name}
            back="/menu"
            bg={
              data.taxon.image
                ? process.env.REACT_APP_API_URL +
                  "/media/image/" +
                  data.taxon.image
                : null
            }
            overlay={true}
          />
        </header>
        <main className="show-page-container">
          {data.items
            ? data.items.map((item, index) => {
                return (
                  <MenuCard
                    key={item.id}
                    id={item.id}
                    title={item.name}
                    description={item.description}
                    image={
                      item.image
                        ? process.env.REACT_APP_API_URL +
                          "/media/image/" +
                          item.image
                        : null
                    }
                    variants={item.variants}
                    className="card-img"
                  />
                );
              })
            : null}
          {data.childrens
            ? data.childrens.map((children, index) => {
                children.items.map((item) => {
                  return (
                    <div key={item.id}>
                      <h3>{children.taxon}</h3>
                      <MenuCard
                        key={item.id + index}
                        id={item.id}
                        title={item.name}
                        description={item.description}
                        image={
                          item.image
                            ? process.env.REACT_APP_API_URL +
                              "/media/image/" +
                              item.image
                            : null
                        }
                        variants={item.variants}
                      />
                    </div>
                  );
                });
              })
            : null}
        </main>
      </div>
    ) : null;
  }
}

export default ShowPage;
