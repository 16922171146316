import React from "react";
import { Link } from "react-router-dom";
//common
import ProgressiveImage from "common/ProgressiveImage/ProgressiveImage";
//components
import ShopCartWidget from "components/Shop/ShopCartWidget";
//icons
import BackIcon from "assets/img/icons/back.png";

const Banner = ({ title, back, logo, bg, overlay = false, cart = true }) => {
  return (
    <div className="banner">
      {back ? (
        <Link to={back} className="back-button">
          <ProgressiveImage image={BackIcon} alt="atras" />
        </Link>
      ) : null}
      {logo ? (
        <ProgressiveImage image={logo} alt="logo" className="logo" />
      ) : null}
      {/* {bg ? (
        <ProgressiveImage
          image={bg}
          alt="header"
          className={overlay ? "bg-overlay" : null}
        />
      ) : null} */}
      <h3 style={{ color: bg ? "white" : "black" }}>{title}</h3>
      {cart && process.env.REACT_APP_ENABLE_CART ? <ShopCartWidget /> : null}
    </div>
  );
};

export default Banner;
