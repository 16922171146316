import axios from "axios";

let apiBaseUrl = `${process.env.REACT_APP_API_URL}api/v1`;

const api = axios.create({
  baseURL: apiBaseUrl,
});

api.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("wegant_token")
    ? JSON.parse(localStorage.getItem("wegant_token"))
    : null;
    config.headers["Authorization"] = `Bearer ${token.access_token}`;
    return config;
  },
  function (error) {}
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error.response.data);
/*     if ("invalid_grant" === error.response.data.error) {
      refreshUserToken(token);
    } */
  }
);

export default api;
