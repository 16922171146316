import React, { Component } from "react";
import { Link } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
//redux
import { connect } from "react-redux";
import { addToCart } from "redux/actions/cart.actions";
//icons
import CartIcon from "assets/img/icons/cart.png";

class ShopCartWidget extends Component {
  constructor({ items, total, addToCart }) {
    super();
  }

  getTotalItems() {
    const { items } = this.props;
    let total = 0;
    items.forEach((element) => {
      total = total + element.cant;
    });
    return total;
  }

  render() {
    const { items } = this.props;
    return process.env.REACT_APP_ENABLE_CART ? (
      <Link to="/cart" className="shop-cart-widget">
        <Badge badgeContent={this.getTotalItems()} color="secondary">
          <img src={CartIcon} alt="carrito" />
        </Badge>
      </Link>
    ) : null;
  }
}

const mapStateToProps = (state, props) => {
  return {
    items: state.cartReducers.items,
    total: state.cartReducers.total,
  };
};

export default connect(mapStateToProps, { addToCart })(ShopCartWidget);
